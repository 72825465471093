.Join {
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
}

.left-j {
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}

.left-j>hr {
    position: absolute;
    width: 10.5rem;
    border: 2px solid var(--orange);
    border-radius: 20%;
    margin: -10px 0;
}

.left-j>div {
    display: flex;
    gap: 1rem;
}

.right-j {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 36%;
}

.email-container {
    display: flex;
    gap: 1rem;
    background-color: grey;
    padding: 1rem 2rem;
    width: 100%;
}

.email-container>input {
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    width: 100%;
}

::placeholder {
    color: var(--lightgray);
}

.btn-j {
    background-color: var(--orange);
    color: white;
    width: -webkit-fill-available;
    max-width: 7rem;
}

@media screen and (max-width:768px) {
    .Join {
        flex-direction: column;
        gap: 1rem;
    }

    .left-j {
        font-size: x-large;
        flex-direction: column;
    }

    .right-j {
        width: 90%;
        margin: auto;
    }
}

@media screen and (max-width:408px) {
    .btn-j {
        max-width: min-content;
    }

    .email-container {
        padding: 1rem 1rem;
    }
}

